import React, { Fragment } from 'react'
import PropTypes from "prop-types";
import latinnouns from './nouns.json'
import './motto.css';

// latinnouns={latinnouns};
//latinnouns=JSON.parse(latinnouns);
const Generate = (props) => {

  Generate.propTypes = {
    first: PropTypes.number.isRequired, // must be a string and defined
    second: PropTypes.number.isRequired, // must be a string and defined
  };
  
    // let nounArrayWithoutFirst = JSON.parse(JSON.stringify(latinnouns.splice(props.first)))
    // let nounArrayWithoutSecond = JSON.parse(JSON.stringify(latinnouns.splice(props.second)))
// let nounArrayWithoutFirst=latinnouns.splice(props.first);
// let nounArrayWithoutSecond=latinnouns.splice(props.second);
// let nounOne=latinnouns[props.first];
// let nounTwo=latinnouns[props.second];


//let rWord=     latinnouns[latinnouns[Math.floor(Math.random() * latinnouns.length)]].noun;
// let randomN=null;
// while (randomN===props.first || randomN===null) {
//     randomN=Math.floor(Math.random()* latinnouns.length);
// }
// let randomNoun=latinnouns[randomN].noun;
// let randomWord=latinnouns[randomN];
// let arr = [<Model1 {...randomWord}/>,<Model2 {...randomWord}/>];

if (props.first===undefined){
  props.first=0;
}

if (props.second===undefined){
  props.second=0;
}

let arr = [<Model1 {...props}/>,
           <Model2 {...props}/>,
           <Model3 {...props}/>,
           <Model4 {...props}/>,
           <Model5 {...props}/>,
           <Model6 {...props}/>,
           <Model7 {...props}/>,
           <Model8 {...props}/>,
           <Model9 {...props}/>,
           <Model10 {...props}/>,
        
        ];

    return (
        <Fragment>
       <div className='mottoResults'>
        {arr.map((model, index) => (
        <div key = {'model'+index} className='mottoBlock'>
          {model}
        </div>
          )
        )}
        </div>
        </Fragment>
    );
}

const Model1 = (props) => {
  Model1.propTypes = {
    first: PropTypes.number.isRequired, // must be a string and defined
    second: PropTypes.number.isRequired, // must be a number and defined
  };
   // love and friendship 
  // console.log(latinnouns);
  //  console.log(latinnouns[2]);
  // // console.log(latinnouns[2]ultio);
  // if (props.first===undefined){
  //   props.first=0;
  // }

  // if (props.second===undefined){
  //   props.second=0;
  // }

  // console.log(props.first);
  //  console.log(latinnouns[2].noun);
  //  console.log("done");

   return ( 
//    <div>{props.noun}: {props.translation}</div>
//    <div>{latinnouns[props.first].noun} et {latinnouns[props.second].noun}

<Fragment>

        <div className='mottoPhrase'>{latinnouns[props.first].noun} et {latinnouns[props.second].noun}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} and {latinnouns[props.second].translation}</div>
    
</Fragment>
  );
   
}

const Model2 = (props) => {
       // love out of friendship 
    return ( 
<Fragment>
        <div className='mottoPhrase'>{latinnouns[props.first].noun} ex {latinnouns[props.second].ablative}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} out of {latinnouns[props.second].translation}</div>
</Fragment>
  );
 }

 const Model3 = (props) => {
    // love choses friendship 
 return ( 
<Fragment>
     <div className='mottoPhrase'>{latinnouns[props.first].noun} deligit {latinnouns[props.second].accusative}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} chooses {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model4 = (props) => {
    // love without friendship 
 return ( 
<Fragment>
     <div className='mottoPhrase'>{latinnouns[props.first].noun} sine {latinnouns[props.second].ablative}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} without {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model5 = (props) => {
    // love not without friendship 
 return ( 
<Fragment>
     <div className='mottoPhrase'>non sine {latinnouns[props.second].ablative} {latinnouns[props.first].noun}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} is not without {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model6 = (props) => {
    // love loves friendship 
 return ( 
<Fragment>
<div className='mottoPhrase'>{latinnouns[props.first].noun} {latinnouns[props.second].accusative} amat</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} loves {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model7 = (props) => {
  // love nurishes friendship 
return ( 
<Fragment>
<div className='mottoPhrase'>{latinnouns[props.first].noun} {latinnouns[props.second].accusative} alat</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} nurishes {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model8 = (props) => {
  // love or nothing 
return ( 
<Fragment>
<div className='mottoPhrase'>aut {latinnouns[props.first].noun} aut nihil</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} or nothing</div>
</Fragment>
);
}

const Model9 = (props) => {
  // from love to friendship
return ( 
<Fragment>
<div className='mottoPhrase'>a {latinnouns[props.first].ablative} ad {latinnouns[props.second].accusative}</div> <div className='mottoTranslation'>from {latinnouns[props.first].translation} to  {latinnouns[props.second].translation}</div>
</Fragment>
);
}

const Model10 = (props) => {
  // there is love in friendship 
return ( 
<Fragment>
<div className='mottoPhrase'>{latinnouns[props.first].noun} in {latinnouns[props.second].ablative}</div> <div className='mottoTranslation'>{latinnouns[props.first].translation} in {latinnouns[props.second].translation}</div>
</Fragment>
);
}

export default Generate;

